// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'

// Your web app's Firebase configuration
// const firebaseConfig = {
//   apiKey: process.env.API_KEY,
//   authDomain: process.env.AUTH_DOMAIN,
//   projectId: process.env.PROJECT_ID,
//   storageBucket: process.env.STORAGE_BUCKET,
//   messagingSenderId: process.env.MESSAGING_SENDER_ID,
//   appId: process.env.APP_ID,
// }

const firebaseConfig = {

    apiKey: "AIzaSyAk8itdI1lgTNofo54o6F37ZAHUMfZ9IW0",
  
    authDomain: "happyhours-fa82a.firebaseapp.com",
  
    projectId: "happyhours-fa82a",
  
    storageBucket: "happyhours-fa82a.appspot.com",
  
    messagingSenderId: "264826262420",
  
    appId: "1:264826262420:web:114681fe2ac9b746baf581",
  
    measurementId: "G-NMFL3QD504"
  
  };
  

// Initialize Firebase
export const app = initializeApp(firebaseConfig)

// ! remember to change firestore from test mode to production mode
// Initialize Cloud Firestore and get a reference to the service
export const db = getFirestore(app)